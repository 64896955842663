/* @flow */

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuizListData } from "data";

import Wrapper from "components/Wrapper";
import Star from "./assets/star.svg";
import Calculator from "./assets/calculator.svg";
import Trophy from "./assets/trophy.svg";
import cn from "classnames";

import styles from "./styles.scss";

const USPSection = (): React$Node => {
  const {
    info: { code },
    routes: { quizList, calculatorView },
  } = useContext(StoreInfoContext);
  const quizListData = useData(QuizListData);
  const [welcomeQuiz, setWelcomeQuiz] = useState(null);

  const welcomeQuizData = {
    title: "Gör ditt första quiz",
    description: "Tjäna 7 200 Points och få koll på Release Points i vårt välkomstquiz!",
    link: quizList && quizList.toggle !== undefined && quizList.toggle && typeof quizList.url === "string" &&
      quizList.url.length > 0 && `${quizList.url}${welcomeQuiz ? "/" + welcomeQuiz.id : ""}`,
    linkTitle: "Gör quiz",
    svg: <Star />,
  };

  const tele2Links = [
    { title: "Offert/avtal/360", link: "https://secure.360t2.se/T2_login", external: true },
    { title: "Vendo", link: "https://vendo.tele2.se/", external: true },
    { title: "TSO", link: "https://amber.tso.tele2.se/login", external: true },
    { title: "Partner Academy", link: "https://utbildning.tele2.se/", external: true },
    { title: "Dokumentmallar", link: "/dokumentmallar", external: false },
    { title: "Provisionsbrev", link: "/provisionsbrev", external: false },
  ];

  const ReleaseLinks = [
    { title: "Räkna ut dina Points",
      description: "Använd Pointskalkylatorn för att se hur många Points du får för dina affärer.",
      link: calculatorView && calculatorView.toggle !== undefined && calculatorView.toggle &&
      typeof calculatorView.url === "string" &&
      calculatorView.url.length > 0 && calculatorView.url,
      linkTitle: "Till kalkylatorn",
      svg: <Calculator />,
    },
    { title: "Tjäna Points på Prelease",
      description: "Har du koll? Gör quizet och tjäna 1000 Points för varje rätt svar.",
      link: quizList && quizList.toggle !== undefined && quizList.toggle &&
      typeof quizList.url === "string" &&
      quizList.url.length > 0 && quizList.url,
      linkTitle: "Gör quiz",
      svg: <Trophy />,
    },
  ];

  useEffect(() => {
    if (quizListData.state === "LOADED" &&
        quizListData.data &&
        quizListData.data.length > 0
    ) {
      const filterResults = quizListData.data.filter(quiz => quiz.catname && quiz.catname === "welcomeQuiz");
      if (filterResults.length > 0) {
        setWelcomeQuiz(filterResults[0]);
      }
    }
  }, [quizListData]);

  const WelcomeQuizComponent = () => (
    <div className={cn(styles.releaseNavItem, "awardit-uspItem")}>
      {welcomeQuizData.svg}
      <section className={styles.itemContent}>
        <p className={styles.title}>{welcomeQuizData.title}</p>
        <p>{welcomeQuizData.description}</p>
        {typeof welcomeQuizData.link === "string" &&
          <Link to={welcomeQuizData.link}>{welcomeQuizData.linkTitle}</Link>
        }
      </section>
    </div>
  );

  if (code === "tele2_sek") {
    return (
      <Wrapper className={cn(styles.wrapper, "awardit-uspWrapper")}>
        <div className={cn(styles.tele2LinksContainer, "awardit-uspContainer")}>
          {tele2Links.map(item =>
            item.external ?
              <a className="awardit-uspItem" href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a> :
              <Link key={item.title} to={item.link}>{item.title}</Link>)}
        </div>
      </Wrapper>
    );
  }

  if (code === "releasefinans_sek") {
    return (
      <Wrapper className={cn(styles.wrapper, "awardit-uspWrapper")}>
        <div className={cn(styles.releaseLinksContainer, "awardit-uspContainer")}>
          {welcomeQuiz !== null && welcomeQuiz.answered === 0 && (
            <WelcomeQuizComponent />
          )}

          {ReleaseLinks.map(item => (
            <div key={item.title} className={cn(styles.releaseNavItem, "awardit-uspItem")}>
              {item.svg}
              <section className={styles.itemContent}>
                <p className={styles.title}>{item.title}</p>
                <p>{item.description}</p>
                {typeof item.link === "string" &&
                  <Link to={item.link}>{item.linkTitle}</Link>
                }
              </section>
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }

  return null;
};

export default USPSection;
