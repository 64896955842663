/* @flow */

import type { InfoBlock } from "shop-state/types";
import type { NewsPost } from "../NewsListItem";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useData } from "crustate/react";
import { CurrentPageInfoData, TransactionsData } from "data";

import Button from "components/Button";
import { Item } from "components/UiComponents";
import NewsListItem from "../NewsListItem";
import Calculator from "../CalculatorView/Calculator";
import TransactionTable from "components/TransactionTable";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

const HomeHero = (): React$Node => {
  const t = useTranslate();
  const {
    routes,
    content: {
      homeview,
    },
  } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);
  const currentPageInfoData = useData(CurrentPageInfoData);

  const newsCurrentInfo = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.newsview &&
    currentPageInfoData.data.newsview.length > 0 ?
    currentPageInfoData.data.newsview : [];

  const newsCurrentInfoChunks: Array<Array<InfoBlock>> = [];

  if (currentPageInfoData.state === "LOADED" && currentPageInfoData.data.newsview && currentPageInfoData.data.newsview.length > 0) {
    newsCurrentInfoChunks.push(newsCurrentInfo.slice(0, 3));
  }

  return (
    <div className={cn(styles.homeHero, "awardit-homeHero")}>
      <picture className={styles.image}>
        <source
          srcSet={homeview.heroImageSmall ?? ""}
          media={`(max-width: ${parseInt(styles.tiny, 10) - 1}px)`}
        />
        <source
          srcSet={homeview.heroImageLarge ?? ""}
          media={`(min-width: ${parseInt(styles.tiny, 10)}px)`}
        />
        <img alt="" src={homeview.heroImageLarge ?? ""} />
      </picture>
      <div className={styles.content}>
        <div key="featuredContent">
          <div className={styles.currentPageInfoTop}>
            <div className={styles.currentInfo}>
              {homeview.heroPrimaryInfoFieldStatus !== undefined &&
              homeview.heroPrimaryInfoFieldStatus !== null &&
              homeview.heroPrimaryInfoFieldStatus === true &&
                <div className={styles.currentInfoCardWrapper}>
                  <div className={cn(styles.currentInfoCard, "awardit-heroCurrentInfoCard")}>
                    {homeview.heroPrimaryInfoFieldImage ? (
                      <div className={styles.currentInfoCardImageWrapper}>
                        <img src={homeview.heroPrimaryInfoFieldImage} />
                      </div>
                    ) : null }
                    {(homeview.heroPrimaryInfoFieldHeading ||
                      homeview.heroPrimaryInfoFieldText) && (
                      <div className={styles.currentInfoCardContent}>
                        {homeview.heroPrimaryInfoFieldHeading && (
                          <h3>{homeview.heroPrimaryInfoFieldHeading}</h3>
                        )}
                        {homeview.heroPrimaryInfoFieldText && (
                          /* eslint-disable react/no-danger */
                          <div
                            dangerouslySetInnerHTML={{ __html: homeview.heroPrimaryInfoFieldText }}
                            className={cn(styles.currentInfoDescription, "awardit-heroCurrentInfoDescription")}
                          />
                          /* eslint-enable react/no-danger */
                        )}
                      </div>
                    )}
                    {(homeview.heroPrimaryInfoFieldLink &&
                      homeview.heroPrimaryInfoFieldLinkText) && (
                      <div className={styles.currentInfoCardLinkContainer}>
                        <Button to={homeview.heroPrimaryInfoFieldLink} className="awardit-heroInfoCardButton">
                          {homeview.heroPrimaryInfoFieldLinkText}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              }
              {homeview.heroSecondaryInfoFieldStatus !== undefined &&
              homeview.heroSecondaryInfoFieldStatus !== null &&
              homeview.heroSecondaryInfoFieldStatus === true &&
                <div className={styles.currentInfoCardWrapper}>
                  <div className={cn(styles.currentInfoCard, "awardit-heroCurrentInfoCard")}>
                    {homeview.heroSecondaryInfoFieldImage ? (
                      <div className={styles.currentInfoCardImageWrapper}>
                        <img src={homeview.heroSecondaryInfoFieldImage} />
                      </div>
                    ) : null }
                    {(homeview.heroSecondaryInfoFieldHeading ||
                      homeview.heroSecondaryInfoFieldText) && (
                      <div className={styles.currentInfoCardContent}>
                        {homeview.heroSecondaryInfoFieldHeading && (
                          <h3>{homeview.heroSecondaryInfoFieldHeading}</h3>
                        )}
                        {homeview.heroSecondaryInfoFieldText && (
                          /* eslint-disable react/no-danger */
                          <div
                            dangerouslySetInnerHTML={
                              { __html: homeview.heroSecondaryInfoFieldText }
                            }
                            className={cn(styles.currentInfoDescription, "awardit-heroCurrentInfoDescription")}
                          />
                          /* eslint-enable react/no-danger */
                        )}
                      </div>
                    )}
                    {(homeview.heroSecondaryInfoFieldLink &&
                      homeview.heroSecondaryInfoFieldLinkText) && (
                      <div className={styles.currentInfoCardLinkContainer}>
                        <Button to={homeview.heroSecondaryInfoFieldLink} className="awardit-heroInfoCardButton">
                          {homeview.heroSecondaryInfoFieldLinkText}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>

            {/* HomeView content field 1 & 2 (instead of currentInfoCard from NewsView) */}

            {homeview.motivationHeroCalculatorStatus !== undefined &&
            homeview.motivationHeroCalculatorStatus !== null &&
            homeview.motivationHeroCalculatorStatus === true &&
              <div className={styles.calculatorWrapper}>
                <Calculator />
              </div>
            }

            {homeview.motivationHeroNewsStatus !== undefined &&
            homeview.motivationHeroNewsStatus !== null &&
            homeview.motivationHeroNewsStatus === true &&
            currentPageInfoData.state === "LOADED" &&
            currentPageInfoData.data.newsview &&
            currentPageInfoData.data.newsview.length > 0 && (
              <div className={styles.newsListWrapper}>
                <h3 className={styles.newsListTitle}>{t("NEWS.LATEST")}</h3>
                {/* Show three items per slide, max 15 items */}
                {newsCurrentInfoChunks.map((x, i) => (
                  <Item key={`newsListItem${i}`} className={styles.newsItemWrapper}>
                    {x.map(x => ({ ...x, link: routes.newsListView && routes.newsListView.url && x.id ? `${routes.newsListView.url}#newsItem-${x.id}` : "" }))
                      .map((x: NewsPost) => (
                        <NewsListItem key={x.title} item={x} className={styles.newsItem} />
                      ))}
                  </Item>
                ))
                }
                {routes.newsListView && routes.newsListView.url && (
                  <Link to={routes.newsListView.url} className={cn(styles.newsListLink, "awardit-heroInfoCardLink")}>
                    {t("NEWS.SHOW_ALL")}
                  </Link>
                )}
              </div>
            )}

            {homeview.motivationHeroTransactionStatus !== undefined &&
            homeview.motivationHeroTransactionStatus !== null &&
            homeview.motivationHeroTransactionStatus === true &&
            transaction.state === "LOADED" &&
            transaction.data.length > 0 && (
              <div className={styles.transactionWrapper}>
                <TransactionTable />
                {routes.accountTransactionsView && routes.accountTransactionsView.url &&
                  <div className={styles.section__bottom}>
                    <Button
                      className={cn(styles.link, styles.centeredCta)}
                      to={routes.accountTransactionsView?.url}
                    >
                      {t("FOOTER.LINKS.ALL_TRANSACTIONS")}
                    </Button>
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
