/* @flow */

import React from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";

import Cart from "./svg/cart-shopping-solid.svg";
import Key from "./svg/key-solid.svg";

import styles from "./styles.scss";

const AboutWownyckeln = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Helmet title={t("ABOUTVIEW.TITLE")} />

      <div className={cn(styles.fullWidth, styles.hero)}>
        <div className={styles.fullWidth__wrapper}>
          <div className={styles.introField}>
            <h1>Var med i vår nyckel-yra!</h1>
            <p>
              Du har nu möjlighet att bli storvinnare varje månad!
              Det hänger inte på slumpen eller tur utan är direkt kopplat
              till din egen insats.
            </p>
            <p>
              Wownyckeln är vårt interna program som ska hjälpa oss att göra
              rätt saker i rätt tid, med syfte att nå våra gemensamma mål; att
              ha kunden i fokus och att leverera wowservice varje dag.
              Med Wow-nyckeln vill vi stärka ditt engagemang i hela processen
              och samtidigt göra det roligt och givande att arbeta på rätt sätt.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.fullWidth}>
        <div className={styles.fullWidth__wrapper}>
          <div className={styles.cards}>

            <div className={styles.card}>
              <div className={styles.cardContent}>
                <div className={styles.cardImg}>
                  <img src="/assets/wownyckeln/individual-goals.png" />
                </div>
                <div>
                  <h3>Din egen insats</h3>
                  <ul>
                    <li>Save</li>
                    <li>NKI</li>
                    <li>Fadderskap</li>
                  </ul>
                </div>
              </div>
              <a className={styles.cardLink} href="#individual-goals">Läs mer</a>
            </div>

            <div className={styles.card}>
              <div className={styles.cardContent}>
                <div className={styles.cardImg}>
                  <img src="/assets/wownyckeln/activity.png" />
                </div>
                <div>
                  <h3>Extra nycklar</h3>
                  <ul>
                    <li>Jubileum</li>
                    <li>Månadens wow</li>
                  </ul>
                </div>
              </div>
              <a className={styles.cardLink} href="#extra">Läs mer</a>
            </div>
          </div>
        </div>
      </div>

      <Wrapper className={styles.wrapper}>
        <div className={styles.contentField}>
          <div className={styles.imgLeft}>
            <img src="/assets/release/collage.jpg" alt="" />
          </div>
          <div className={styles.infoField}>
            <img src="/assets/release/logorad.jpg" alt="" />
            <h2>Lös in dina nycklar mot kul premier</h2>
            <p>
              I Premieshoppen kan du bland tusentals premier från många kända
              varumärken. Du hittar exempelvis roliga evenemang och resor, klockor,
              surfplattor, parfym, verktyg, heminredning, sportkläder, grillar
              och mycket annat. Du betalar med dina nycklar, enkelt och smidigt.
            </p>
            <p>
              <a href="/all-products">Kika in i shoppen</a> och hitta dina favoriter!
              Har du inte nog med nycklar redan nu för att köpa din favoritprodukt,
              spara den i önskelistan för att lätt hitta den när du fått nycklar nog!
            </p>
            <Button to="/all-products" variant="primary">
              <Cart />
              <span>Utforska premieshoppen</span>
            </Button>
          </div>
        </div>
      </Wrapper>

      <div className={styles.fullWidth}>
        <div className={styles.fullWidth__wrapper}>

          <div id="individual-goals">
            <h1>Din egen insats</h1>

            <div className={styles.largeBlock__borderTop}>
              <h2>Save</h2>
              <p>
                För att nå vårt mål för antal kunder gäller det att vi räddar kvar kunder som
                vill avsluta. Därför får du nycklar för kunder och lotter. Ditt resultat på
                Save räknas månadsvis och du får nycklar enligt följande:
              </p>
              <div className={styles.grid}>
                <div>
                  <div className={styles.headingBlock}>
                    <Key />
                    <h3>Save kundnivå:</h3>
                  </div>
                  <ul>
                    <li>En save på kundnivå = 125 nycklar</li>
                  </ul>
                </div>
                <div>
                  <div className={styles.headingBlock}>
                    <Key />
                    <h3>Save lottnivå:</h3>
                  </div>
                  <ul>
                    <li>En save på lottnivå = 125 nycklar</li>
                  </ul>
                </div>
              </div>
              <span>Frekvens: Resultat varje vecka.</span>
            </div>

            <div className={styles.largeBlock__borderTop}>
              <h2>NKI</h2>
              <p>
                Lika viktigt som det är att vi svarar i tid på våra samtal är att
                kunderna känner sig nöjda efter kontakten med oss. Här får du nycklar
                beroende på hur bra din NKI är. Du mäts både på Telefon och på chatt.
                Du måste ha fått in minst 20 svar under månaden för att få nycklar för NKI.
              </p>
              <div className={styles.headingBlock}>
                <Key />
                <h3>Nycklar</h3>
              </div>
              <ul>
                <li>Telefon: 96% = 4 000 nycklar</li>
              </ul>
              <div className={styles.headingBlock}>
                <h3>NKI Mail</h3>
              </div>
              <div className={styles.headingBlock}>
                <Key />
                <h3>Nycklar</h3>
                <span>(månadsvis)</span>
              </div>
              <ul>
                <li>Mail: 90% = 4 000 nycklar</li>
              </ul>
            </div>

            <div className={styles.largeBlock__borderTop}>
              <h2>Fadderskap</h2>
              <p>
                Att våra nya kollegor får en riktigt bra start är väldigt viktigt för oss!
                Vi vill att de ska komma in i arbete och vårt gäng på ett snabbt, smidigt
                och trevligt sätt. Därför har vi valt att belöna dig som är Fadder med 3000
                nycklar. Det är cheferna som utser Fadder och det är fritt att anmäla sitt
                intresse till sin chef.
              </p>
              <p className={styles.bold}>Kriterier för att få nycklar för Fadderskap:</p>
              <ul>
                <li>Finnas på plats alla 5 dagar under den nyanställdes introduktionsvecka</li>
                <li>Gå igenom alla delar på tilldelad lista med den nyanställda</li>
                <li>Ge avprickad lista till sin chef</li>
              </ul>
              <div className={styles.headingBlock}>
                <Key />
                <h3>Nycklar</h3>
              </div>
              <ul>
                <li>Vid 100 % = 3000 nycklar</li>
              </ul>
            </div>
          </div>

          <div id="extra">
            <h1>Extra nycklar</h1>

            <div className={styles.gridBlockContainer}>

              <div className={styles.gridBlock}>
                <h2>Jubileum</h2>
                <p>
                  Ju längre du jobbar hos Postkodlotteriet, ju mer erfarenheter och kunskap
                  har du. Och blir då givetvis en viktig tillgång för oss. Därför tjänar du
                  nycklar baserat på din anställningstid.
                </p>
                <p>
                  Jubileum räknas från anställningsdatum (provanställning inräknat) enligt
                  nedanstående tabell. Det vill säga, om du påbörjar din anställning 3 september
                  2018 så rapporterar du ditt jubileum för 8 månader efter den 3 maj 2019 och
                  får då 5 000 nycklar! Anställningen behöver vara sammanhängande utan avbrott.
                </p>
                <p>
                  Du rapporterar själv in när du har ett jubileum enligt tabellen nedan.
                  Där hittar du också mer exakta kriterier för Jubileum.
                </p>
                <div className={styles.headingBlock}>
                  <Key />
                  <h3>Nycklar</h3>
                </div>
                <ul>
                  <li>Jubileum 8 mån = 5 000 nycklar</li>
                  <li>Jubileum 12 månader = 6 000 nycklar</li>
                  <li>Jubileum 18 månader = 8 000 nycklar</li>
                  <li>Jubileum 2 år = 10 000 nycklar</li>
                  <li>Jubileum 3 år = 12 000 nycklar</li>
                </ul>
                <Button className={styles.btnBlock} variant="secondary" to="#">
                  Rapportera in jubileum
                </Button>
              </div>

              <div className={styles.gridBlock}>
                <h2>Månadens WOW</h2>
                <p>
                  Varje månad utses en medarbetare som har gjort det där lilla extra. Det kan
                  röra sig om allt ifrån varit extra hjälpsam på något sätt till att ha haft höga
                  resultat under lång tid. Månadens WOW erhåller givetvis ett gäng nycklar för sin
                  insats. Vinnaren publiceras på startsidan med bild och motivering till vinsten.
                </p>
                <p>
                  För att vara en kandidat till månadens WOW och ha chansen att få 5000 nycklar så
                  behöver du skicka in en bild på dig själv till din närmaste chef. Det ska vara en
                  bild som du godkänner att vi använder i ”wow-nyckeln” om du blir utvald. Bilden
                  kommer inte att användas i annat syfte utan att du först blir tillfrågad och
                  kommer att sparas tills du skickar in en uppdaterad bild eller avslutad
                  anställning. Bild skickas till närmsta chef.
                </p>
                <div className={styles.headingBlock}>
                  <Key />
                  <h3>Nycklar</h3>
                </div>
                <ul>
                  <li>5 000 nycklar till Månadens WOW.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWownyckeln;
