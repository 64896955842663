/* @flow */

import React from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import Button from "components/Button";

import Helicopter from "./svg/helicopter.svg";
import CircleCheck from "./svg/circle-check.svg";
import GraduationCap from "./svg/graduation-cap.svg";
import Handshake from "./svg/handshake-angle.svg";
import Users from "./svg/users.svg";

import styles from "./styles.scss";

const AboutDreamteam = (): React$Node => {
  return (
    <div className={styles.block}>
      <Helmet
        title="Om DreamTeam"
      />

      <div className={styles.fullWidth__Top}>
        <div className={styles.fullWidth__TopWrapper}>
          <h1 className={cn("awardit-aboutPageHeading")}>Du är en del av vårt DreamTeam</h1>
          <p>
            Du som är anställd hos oss ska alltid känna att ditt engagemang
            betalar sig och att vi uppskattar dig. Därför får du poäng
            direkt när du hjälper till med tips, lär dig mer om företaget
            i våra quiz och när vi uppfyller våra mål! Poängen du får är
            din valuta som du själv kan lösa in mot valfria premier i
            premieshoppen med över 4 000 produkter och upplevelser.
          </p>
          <h3>Välkommen till DreamTeam!</h3>
        </div>
      </div>

      <Wrapper className={styles.cards}>
        <h2>6 sätt att samla poäng:</h2>
        <div className={styles.cardsContainer}>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><CircleCheck /></div>
            </div>
            <span>14 000 poäng/månad</span>
            <h3>Träffa våra uppsatta mål</h3>
            <p>När vi tillsammans uppfyllt målen gällande omsättning och/eller täckningsbidrag.
              Ju mer vi överträffar, desto fler poäng!
            </p>
            <Link className={styles.cardLink} to="/resultat">Visa resultat</Link>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><Users /></div>
            </div>
            <span>27 000 poäng</span>
            <h3>Tipsa om en ny medarbetare</h3>
            <p>Känner du någon som skulle passa in i gänget?</p>
            <Link className={styles.cardLink} to="/forms/5126086">Tipsa om medarbetare</Link>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><Helicopter /></div>
            </div>
            <span>12 000 poäng</span>
            <h3>Tipsa om innovationer</h3>
            <p>Skicka in dina idéer på hur vi kan bli bättre!</p>
            <Link className={styles.cardLink} to="/forms/5145732">Tipsa oss här</Link>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><Handshake /></div>
            </div>
            <span>27 000 poäng</span>
            <h3>Skicka in ett nytt lead</h3>
            <p>Vet du om någon som inte handlar av oss än men som skulle behöva?</p>
            <Link className={styles.cardLink} to="/forms/5140029">Tipsa oss här</Link>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><Handshake /></div>
            </div>
            <span>5 000 poäng</span>
            <h3>Tipsa om ny referenskund</h3>
            <p>Varje nöjd kund är en guldkund.</p>
            <Link className={styles.cardLink} to="/referenskund">Tipsa oss här</Link>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><GraduationCap /></div>
            </div>
            <span>100 poäng för varje rätt svar</span>
            <h3>Svara på quiz</h3>
            <p>Med jämna mellanrum lägger vi upp nya quiz. Håll utkik!</p>
            <Link className={styles.cardLink} to="/quiz">Se aktuellt quiz</Link>
          </div>

        </div>

      </Wrapper>

      <div className={styles.fullWidth__Bottom}>
        <div className={styles.fullWidth__BottomWrapper}>
          <div className={styles.pointsContent}>
            <h2>Lös in poängen i premieshoppen</h2>
            <p>
              I Premieshoppen hittar du produkter för alla intressen och smaker.
              Evenemang och presentkort, klockor, surfplattor, parfym,
              verktyg, grillar och mycket annat. Du betalar med dina poäng, enkelt och
              smidigt. <Link to="/all-products">Kika in i shoppen</Link> och hitta dina favoriter!
            </p>
            <Button to="/all-products" variant="primary">
              Gå till premieshoppen
            </Button>
            <img src="/assets/dreamteam/logos_white.jpeg" alt="" />
          </div>
          <img className={styles.imgRight} src="/assets/dreamteam/premier.jpeg" alt="" />
        </div>
      </div>

      <Wrapper className={styles.bottomBlocksWrapper}>
        <div className={styles.bottomBlockContent}>
          <h2>Alla får poäng när vi uppfyller målen</h2>
          <h3>Omsättning</h3>
          <p>
            För varje månad vi når vi budget delas det ut <strong> 6 000 poäng </strong> till
            var och en. Om vi inte når vår budget en månad läggs dessa poäng i en pott
            för året och den potten delas ut om
            vi når budget totalt för hela året. <Link to="/resultat/1185">Se respektive månads budget här »</Link>
          </p>

          <h3>Täckningsbidrag</h3>
          <p>
            För varje månad som vi når målet utgår <strong>8 000 poäng</strong> per medarbetare.
          </p>
        </div>
        <img className={styles.imgRight} src="/assets/dreamteam/dreamteam-footprints.png" alt="" />
      </Wrapper>
    </div>
  );
};

export default AboutDreamteam;
