/* @flow */

import type { CurrentInfoItem, CurrentInfoRoute } from "shop-state/types";

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet-async";

import { CurrentInfoItemData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import Breadcrumbs from "components/Breadcrumbs";
import LoadingView from "components/LoadingView";
import ErrorView from "components/ErrorView";
import NotFoundView from "components/NotFoundView";
import Wrapper from "components/Wrapper";
import { Title } from "components/UiComponents";

import styles from "./styles.scss";

type Props = {
  data: CurrentInfoItem,
  parentRoute: ?CurrentInfoRoute,
};

type DummyProps = {
  title: ?string,
  parentRoute: ?CurrentInfoRoute,
};

const CurrentInfoItemView = ({ data, parentRoute }: Props) => {
  const { info: { code } } = useContext(StoreInfoContext);

  if (code === "tele2_sek" || code === "eniro_sek") {
    return (
      <Wrapper className={cn(styles.fullWidthWrapper, "awardit-wrapper")}>
        <Helmet title={data.title || ""} />
        <div className={styles.contentWrapper}>
          {/* eslint-disable react/no-danger */}
          {data.content &&
          <div className="typography" dangerouslySetInnerHTML={{ __html: data.content }} />
          }
          {/* eslint-enable react/no-danger */}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={cn(styles.wrapper, "awardit-CurrentInfoItemWrapper")}>
      <Helmet title={data.title || ""} />
      {parentRoute &&
        <Breadcrumbs current={data.title || ""} links={[{ url: parentRoute.url, name: parentRoute.title }]} />
      }
      <div className={cn(styles.contentWrapper, "awardit-CurrentInfoItemContentWrapper")}>
        {data.title &&
        <Title>{data.title}</Title>
        }
        {/* eslint-disable react/no-danger */}
        {data.content &&
        <div className="typography" dangerouslySetInnerHTML={{ __html: data.content }} />
        }
        {/* eslint-enable react/no-danger */}
      </div>
    </Wrapper>
  );
};

const CurrentInfoItemDummyView = ({ title, parentRoute }: DummyProps) => {
  return (
    <Wrapper className={styles.dummy}>
      <Helmet title={title || ""} />
      {parentRoute &&
        <Breadcrumbs current={title || ""} links={[{ url: parentRoute.url, name: parentRoute.title }]} />
      }
      <div className={styles.contentWrapper}>
        {title &&
        <Title>{title}</Title>
        }
        <div key="ciid_1" className={styles.row} />
        <div key="ciid_3" className={styles.row} />
        <div key="ciid_2" className={styles.row} />
        <div key="ciid_4" className={styles.row} />
        <div key="ciid_5" className={styles.row} />
        <div key="ciid_6" className={styles.row} />
      </div>
    </Wrapper>
  );
};

const CurrentInfoItemViewWrapper = (
  { parentRoute }: { parentRoute: ?CurrentInfoRoute }
): React$Node => {
  const data = useData(CurrentInfoItemData);
  const location = useLocation();

  if (data.state === "LOADING") {
    if (typeof location.state?.hint?.title === "string") {
      return (
        <CurrentInfoItemDummyView
          parentRoute={parentRoute}
          title={location.state.hint.title}
        />
      );
    }

    return <LoadingView />;
  }

  if (data.state === "ERROR") {
    return <ErrorView />;
  }

  if (data.state === "LOADED" && data.data) {
    return <CurrentInfoItemView parentRoute={parentRoute} data={data.data} />;
  }

  return <NotFoundView />;
};

export default CurrentInfoItemViewWrapper;
