/* @flow */

import type { CurrentInfoListItem, CurrentInfoRoute } from "shop-state/types";

import React, { useContext, useEffect, useState } from "react";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useData } from "crustate/react";
import { CurrentInfoListData } from "data";
import { currentInfoPage as currentInfoPageQuery, connectedPartners } from "queries";
import Breadcrumbs from "components/Breadcrumbs";
import ErrorView from "components/ErrorView";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { Title } from "components/UiComponents";
import HeroIntro from "components/HeroIntro";
import TagsFilter from "components/CurrentInfo/CurrentInfoListView/TagsFilter";
import CurrentInfoList, { CurrentInfoDummyList } from "components/CurrentInfo/CurrentInfoListView/List";

import styles from "components/CurrentInfo/CurrentInfoListView/styles.scss";

type Props = {
  route: CurrentInfoRoute,
  list: Array<CurrentInfoListItem>,
  selectedTags: Array<string>,
  setSelectedTags: (Array<string>) => void,
};

type DummyProps = {
  route: CurrentInfoRoute,
};

type WrapperProps = {
  route: CurrentInfoRoute,
};

const CurrentInfoListView = ({ route, list, selectedTags, setSelectedTags }: Props) => {
  const { content, content: { heroIntroView } } = useContext(StoreInfoContext);

  if (content.currentInfoView5.orderByDate !== null &&
    content.currentInfoView5.orderByDate !== undefined &&
    content.currentInfoView5.orderByDate === true) {
    list.sort((x, y) => (new Date(x.fromDate ? x.fromDate : "") < new Date(y.fromDate ? y.fromDate : "")) ? 1 : -1);
  }

  return (
    <>
      <Helmet title={route.title} />
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro
          title={route.title}
          description={content[route.name] &&
          content[route.name].introDescription}
        />
      }
      <Wrapper className={cn(styles.currentInfoListView, "awardit-CurrentInfoWrapper", `awardit-${route.name}`)}>
        <Breadcrumbs current={route.title || ""} />
        {Boolean(heroIntroView.toggle) === false &&
        <>
          <Title className={styles.title}>{route.title}</Title>
          {(
            <TagsFilter
              route={route}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          )}
          {/* eslint-disable react/no-danger */}
          {content[route.name] && content[route.name].introDescription &&
            <div
              dangerouslySetInnerHTML={{ __html: content[route.name].introDescription }}
              style={{ marginBottom: "4rem", maxWidth: "100ch" }}
            />
          }
          {/* eslint-enable react/no-danger */}
        </>
        }
        <CurrentInfoList path={route.url} list={list} />
      </Wrapper>
    </>
  );
};

const CurrentInfoListDummyView = ({ route }: DummyProps) => {
  const { content: { heroIntroView } } = useContext(StoreInfoContext);
  return (
    <>
      <Helmet title={route.title} />
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro title={route.title} />
      }
      <Wrapper className={cn(styles.currentInfoListView, "awardit-CurrentInfoWrapper", `awardit-${route.name}`)}>
        <Breadcrumbs current={route.title || ""} />
        {Boolean(heroIntroView.toggle) === false &&
          <Title>{route.title}</Title>
        }
        <CurrentInfoDummyList />
      </Wrapper>
    </>
  );
};

const CurrentInfoListViewWrapper = ({ route }: WrapperProps): React$Node => {
  const initialPage = useData(CurrentInfoListData);
  const client = useClient();
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredPage, setFilteredPage] = useState();

  useEffect(() => {
    const getTags = async (route: CurrentInfoRoute, tags: Array<string>) => {
      const { connectedPartners: partners } = await client(connectedPartners);
      const primaryPartner = partners ?
        partners.find(partner => partner.primary === true) : null;

      const { currentInfoPage: page } = await client(currentInfoPageQuery, {
        page: route.key,
        tags,
        prio: primaryPartner ? primaryPartner.name : null,
      });

      if (page.result === "SUCCESS") {
        setFilteredPage(page);
      }
    };

    getTags(route, selectedTags);
  }, [selectedTags]);

  if (initialPage.state === "LOADING") {
    return <CurrentInfoListDummyView route={route} />;
  }

  if (initialPage.state === "ERROR") {
    return <ErrorView />;
  }

  return (
    <CurrentInfoListView
      route={route}
      list={filteredPage && filteredPage.result === "SUCCESS" ? filteredPage.data : initialPage.data}
      selectedTags={selectedTags}
      setSelectedTags={setSelectedTags}
    />
  );
};

export default CurrentInfoListViewWrapper;
