/* @flow */

import type { CurrentInfoListItem } from "shop-state/types";

import React from "react";

import { stutter } from "helpers/animation";
import Card, { DummyCard } from "components/CurrentInfo/CurrentInfoListView/Card";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  path: string,
  list: $ReadOnlyArray<CurrentInfoListItem>,
};

const NUM_DUMMYCARDS = 6;

const CurrentInfoList = ({ path, list }: Props): React$Node => {
  const _stutter = stutter(100, 100);

  return (
    <div className={styles.list} id={path}>
      {list.map((x, i) => (
        <div
          key={x.title}
          style={{
            animationDelay: _stutter(i),
          }}
          className={cn("typography", styles.listItem)}
        >
          <Card key={x.id} path={path} block={x} />
        </div>
      ))}
    </div>
  );
};

export const CurrentInfoDummyList = (): React$Node => {
  return (
    <div className={styles.list}>
      {Array.from({ length: NUM_DUMMYCARDS }).map((_, i) => (
        <div key={i} className={styles.listItem}>
          <DummyCard />
        </div>
      ))}
    </div>
  );
};

export default CurrentInfoList;
