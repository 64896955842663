/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";

import React, { useState, useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { CustomerData } from "data";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { focusInvalidField } from "helpers/utils";
import Field from "components/Field";
import { useData, useSendMessage } from "crustate/react";
import { login } from "state/customer";
import { StoreInfoContext } from "entrypoint/shared";
import styles from "./styles.scss";

type Props = {
  className?: string,
};

const validation = rules([
  isRequired("username"),
  isRequired("password"),
]);

const LoginForm = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const { routes, content: { loginview } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const sendMessage = useSendMessage();
  const [formState, setFormState] = useState<FormData>({});
  const loading = customer.state === "LOGGING_IN";

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    sendMessage(login(formState.username.toString(), formState.password.toString()));
  };

  const errors = validation(formState);

  return (
    <div
      className={cn(styles.block, className, "awardit-loginForm")}
      style={loginview.formOpacity ? { backgroundColor: `rgba(255, 255, 255, ${loginview.formOpacity})` } : {}}
    >
      <div>
        <h3>{t("LOGIN.TITLE")}</h3>
        <Form
          method="POST"
          value={formState}
          errors={errors}
          onError={focusInvalidField}
          onSubmit={submit}
          onChange={x => setFormState({ ...formState, ...x })}
        >
          <div className={styles.input}>
            <Field type="text" name="username" label={loginview.usernameText ?? ""} />
          </div>

          <div className={styles.input}>
            <Field type="password" name="password" autoComplete="off" label={loginview.userPasswordText ?? ""} />
          </div>

          <Button
            id="login"
            type="submit"
            className={cn(styles.button, "awardit-loginFormButton")}
            loading={loading}
            variant="primary"
          >
            {t("LOGIN.SUBMIT")}
          </Button>
          <noscript>
            <p className={styles.noScriptParagraph}>
              {t("NO_JAVASCRIPT.LOGIN")}
            </p>
          </noscript>
        </Form>
      </div>
      <div className={styles.bottom}>
        {routes.passwordResetView && routes.passwordResetView.url && (
          <div className={cn(styles.forgot_password, "awardit-loginFormForgotPassword")}>
            <p>{loginview.forgotPasswordButtonHeading ?? ""}</p>
            {loginview.forgotPasswordButtonText && routes.passwordResetView?.url &&
              <Link
                to={routes.passwordResetView.url}
              >
                {loginview.forgotPasswordButtonText}
              </Link>
            }
          </div>
        )}

        {loginview.createAccountEnabled !== undefined &&
        loginview.createAccountEnabled !== null &&
        loginview.createAccountEnabled === true &&
        routes.registerView && routes.registerView.url &&
          <div className={cn(styles.no_account, "awardit-loginFormNoAccount")}>
            {routes.registerView?.url &&
              <Link to={routes.registerView.url}>{loginview.createAccountButtonText ?? t("LOGIN.CREATE_ACCOUNT")}</Link>
            }
          </div>
        }
      </div>

    </div>
  );
};

export default LoginForm;
