/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import Button from "components/Button";

import File from "./svg/file-invoice-dollar.svg";
import Cap from "./svg/graduation-cap.svg";
import Cart from "./svg/cart-shopping.svg";
import Hand from "./svg/hand-shake.svg";

import styles from "./styles.scss";

const AboutReleasePoints = (): React$Node => {
  return (
    <div className={styles.block}>
      <Helmet
        title="Så funkar Release Points"
      />

      <div className={styles.fullWidth__Top}>
        <div className={styles.fullWidth__TopWrapper}>
          <h1>Så här fungerar det!</h1>
          <p>I Release Points samlar ni Points som sedan kan växlas in i vår premieshop
            med massor av produkter och presentkort.
          </p>
        </div>
      </div>

      <Wrapper className={styles.cards}>
        <h2>I Release Points samlar ni Points inom tre områden:</h2>
        <div className={styles.cardsContainer}>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><File /></div>
            </div>
            <h3>Finansierade kontrakt</h3>
            <a className={styles.cardLink} href="#kontrakt">Läs mer </a>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><Hand /></div>
            </div>
            <h3>Leads</h3>
            <a className={styles.cardLink} href="#leads">Läs mer </a>
          </div>

          <div className={styles.card}>
            <div className={styles.imgContainer}>
              <div className={styles.container}><Cap /></div>
            </div>
            <h3>Quiz</h3>
            <a className={styles.cardLink} href="#quiz">Läs mer </a>
          </div>

        </div>

      </Wrapper>

      <div className={styles.fullWidth__Bottom}>
        <div className={styles.fullWidth__BottomWrapper}>
          <img className={styles.imgLeft} src="/assets/release/collage.jpg" alt="" />
          <div className={styles.pointsContent}>
            <img src="/assets/release/logorad.jpg" alt="" />
            <h2>Växla in Points mot premier</h2>
            <p>
              I Premieshoppen finns produkter av hög kvalitet för alla intressen
              och smaker. Ni hittar exempelvis roliga evenemang och resor, klockor,
              surfplattor, parfym,verktyg, grillar och mycket annat. Du betalar med
              dina Points, enkelt och smidigt.
            </p>
            <p><a href="/all-products">Kika in i shoppen</a> och hitta dina favoriter!</p>
            <Button to="/all-products" variant="primary">
              <Cart />
              Lös in dina points i premieshoppen
            </Button>
          </div>
        </div>
      </div>

      <Wrapper className={styles.bottomBlocksWrapper}>
        <div className={styles.bottomBlocks}>
          <div className={styles.bottomBlock} id="kontrakt">
            <div className={styles.bottomBlockIcon}>
              <File />
            </div>
            <div className={styles.bottomBlockWrapper}>
              <h2>Kontrakt</h2>
              <p>Ni erhåller Points för samtliga kontrakt/avtal ni finansierar via
                Release Finans. Utöver de Points ni erhåller för kontraktet som sådant,
                baseras antalet Points på det finansierade beloppets volym. För ett
                kontakt får ni alltså Points i två kategorier:
              </p>

              <ul>
                <li><strong>Kontraktpoints:</strong> 1 startat kontrakt ger 400 Points
                  (Startat kontrakt = när kompletta avtalshandlingar är Release tillhanda
                  och vi kan betala er faktura)
                </li>
                <li><strong>Volympoints</strong>: Varje finansierad krona ger 0,085
                  Points
                </li>
              </ul>

              <p><em>Exempel: </em>Ett kontrakt på 50 000 kronor ger 4 250 Points + 400
                Points, alltså totalt 4 650 Points. Ett kontrakt på 100 000 kronor ger
                8 500 Points + 400 Points, alltså totalt 8 900 Points. Och så vidare.
              </p>

              <p>Inläsning till Pointsprogrammet av tecknade kontrakt sker för
                närvarande veckovis. Detta innebär att det kan dröja upp till en vecka
                innan ni ser resultatet av era nytecknade kontrakt i saldovyn.
              </p>
            </div>
          </div>

          <div className={styles.bottomBlock} id="leads">
            <div className={styles.bottomBlockIcon}>
              <Hand />
            </div>
            <div className={styles.bottomBlockWrapper}>
              <h2>Leads</h2>
              <p>Tjäna extra Points genom att tipsa oss om framtida samarbetspartners.</p>

              <p>Vi på Release Finans vill gärna att ni tipsar oss om nya potentiella
                samarbetspartners. Som tack för att ni tar er tid och tipsar oss erhåller
                ni Points och är tipset så bra att det genererar ett samarbete finns det
                ytterligare ett stort antal Points att hämta hem. Ett inlämnat tips kan
                alltså generera Points i tre olika steg.
              </p>

              <ul>
                <li>Godkänt lead ger 5 000 Points</li>
                <li>Lead som genererar samarbete ger ytterligare 20 000 Points</li>
                <li>Tipset (numera samarbetspartnern) startar 2 avtal &ndash;
                  ytterligare 20 000 Points
                </li>
              </ul>

              <p><strong>Ett lead måste uppfylla följande kriterier för att godkännas:</strong></p>

              <ul>
                <li>Företaget säljer finansierbar utrustning</li>
                <li>Företaget bedriver B2B-försäljning</li>
                <li>Företaget är ej i kontakt med- eller är befintlig samarbetspartner
                  till Release Finans idag
                </li>
                <li>Företaget säljer inte något av följande; entreprenadmaskiner eller fordon</li>
                <li>Företaget kreditgodkänns av Release Finans</li>
                <li>Företaget och kontaktpersonen har inte inkommit som lead tidigare</li>
              </ul>

              <p>Points för ett godkänt inlämnat lead delas ut efter att vi kontrollerat
                att tipset uppfyller ovan angivna kriterier. Godkänns ert lead kommer Points
                dyka upp i din saldovyn på portalen inom 15 arbetsdagar från att ni
                skickade in tipset.
              </p>

              <p>De Points ni kan erhålla ifall ert lead genererat ett samarbete kan dröja.
                Detta beror helt enkelt på hur kontakten med den tipsade parten utvecklas.
                Definitionen av ett samarbete är att ett samarbetsavtal finns på plats. Har
                inget samarbete kunnat inledas inom 6 månader kommer ni meddelas detta och
                points uteblir.
              </p>

              <p>Uppföljning och utdelning av Points för 2 startade avtal sker månadsvis.
                Förutsättning för dessa Points är att tidigare nivåer är uppfyllda, d.v.s.
                att ett samarbetsavtal finns på plats.
              </p>

              <p>Ni erbjuds möjlighet att lämna upp till 10 leads per år.</p>

              <p>Tack på förhand för de tips ni väljer att delge oss.</p>

              <Button to="/leads" variant="primary" className={styles.bottomBlockButton}>
                Tipsa om lead
              </Button>
            </div>
          </div>

          <div className={styles.bottomBlock} id="quiz">
            <div className={styles.bottomBlockIcon}>
              <Cap />
            </div>
            <div className={styles.bottomBlockWrapper}>
              <h2>Quiz</h2>
              <p>Emellanåt erbjudas möjligheten att tjäna extra Points genom att svara
                på kunskapstester med 1 X 2-frågor om Release Finans, våra tjänster,
                aktiviteter, kampanjer o.s.v. Quizen består av 5-10 frågor och ni får
                Points för varje korrekt svar.
              </p>

              <ul>
                <li>Antalet Points för ett korrekt svar kan varierar mellan olika quiz,
                  men varje rätt svar ger minst <strong>200 points</strong>
                </li>
              </ul>

              <p>De Points ni erhåller för era korrekta svar delas ut direkt efter att
                ni besvarat frågorna i quizen.
              </p>

              <p>Håll ögonen öppna efter detta roliga och enkla sättet att utöka
                Pointssaldot!
              </p>
            </div>
          </div>

        </div>
      </Wrapper>
    </div>
  );
};

export default AboutReleasePoints;
