/* @flow */

import type { Mode } from "state/view-mode";

import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";
import Questionnaires from "components/AppHeader/Large/SubMenu/questionnaires";
import Checkout from "components/AppHeader/Large/SubMenu/checkout";
import Shop from "components/AppHeader/Large/SubMenu/shop";
import Earn from "components/AppHeader/Large/SubMenu/earn";
import useOnFullMenuHiddenRoute from "helpers/use-on-full-menu-hidden-route";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  mode: Mode,
  setMode: Mode => void,
};

export const useHideSubMenu = (): boolean => {
  const { pathname } = useLocation();
  const onFullMenuHiddenRoute = useOnFullMenuHiddenRoute();

  return pathname === "/" ||
  pathname.includes("index.html") ||
  onFullMenuHiddenRoute;
};

const SubMenu = ({ mode, setMode }: Props): React$Node => {
  const { routes } = useContext(StoreInfoContext);
  const hideSubMenu = useHideSubMenu();
  const questionnaireViewUrl = routes.questionnaireView && routes.questionnaireView.url ? routes.questionnaireView.url : "";

  const QUESTIONNAIRE_ROUTES = [
    questionnaireViewUrl,
    questionnaireViewUrl + "/:slug",
  ];

  const CHECKOUT_ROUTES = [
    routes.checkoutView?.url,
    routes.checkoutSuccessView?.url,
  ].filter(Boolean);

  const EARN_ROUTES = [
    routes.earnView?.url,
  ].filter(Boolean);

  if (hideSubMenu) {
    return null;
  }

  return (
    <div className={cn(styles.block, "awardit-appSubHeader")}>
      <Switch>
        <Route path={CHECKOUT_ROUTES} component={Checkout} />
        <Route path={QUESTIONNAIRE_ROUTES} component={Questionnaires} />
        <Route path={EARN_ROUTES} component={Earn} />
        <Route
          path="/(.+)"
          render={props => (
            <Shop {...props} mode={mode} setMode={setMode} />
          )}
        />
      </Switch>
    </div>
  );
};

export default SubMenu;
