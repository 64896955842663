/* @flow */

import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { useData, useSendMessage } from "crustate/react";
import { loadReportsHistory } from "state/reports";
import { ReportHistoryData, ReportHistoryListData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import useDevice from "helpers/use-device";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { SearchableDropdown, DropdownItem, Pagination } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import HistoryTableLarge, { HistoryTableLargeDummy } from "components/ReportsView/ReportsHistoryView/HistoryTableLarge";
import HistoryTableSmall, { HistoryTableSmallDummy } from "components/ReportsView/ReportsHistoryView/HistoryTableSmall";
import { PAGE_SIZE } from "effects/reports";
import cn from "classnames";

import styles from "./styles.scss";

type PaginationButtonT = {
  page: number,
  active: boolean,
  children: React$Node,
};

const ReportsHistoryView = (): React$Node => {
  const t = useTranslate();
  const reportHistory = useData(ReportHistoryData);
  const reportHistoryListData = useData(ReportHistoryListData);
  const isDesktop = useDevice("gt", 801);
  const { routes, content: { reportsview } } = useContext(StoreInfoContext);
  const [current, setCurrent] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentDistrict, setCurrentDistrict] = useState("");
  const sendMessage = useSendMessage();

  const totalPages =
    reportHistory.state === "LOADED" &&
    reportHistory.data.size !== null &&
    reportHistory.data.size !== undefined ?
      Math.ceil(reportHistory.data.size / PAGE_SIZE) : 0;

  const load = useCallback((page: number) => {
    sendMessage(loadReportsHistory({
      id: currentDistrict,
      pageNumber: (page - 1),
      pageLimit: PAGE_SIZE,
    }));

    setCurrentPage(page);
  }, [sendMessage, loadReportsHistory, reportHistory, currentDistrict]);

  useEffect(() => {
    load(currentPage);
  }, [currentDistrict]);

  const PaginationButton = ({ active, children, page }: PaginationButtonT) => {
    return (
      <button
        type="button"
        disabled={active}
        className={
          cn(styles.paginationButton, { [styles.active]: active })
        }
        onClick={() => {
          load(page);
          window.scroll({ top: 0, left: 0, behavior: "auto" });
        }}
      >
        {children}
      </button>
    );
  };

  return (

    <Wrapper className={styles.wrapper}>
      <Helmet title={reportsview.reportHistoryTitle ?? ""} />
      <Breadcrumbs current={reportsview.reportHistoryTitle ?? ""} />

      <div className={styles.intro}>
        {reportsview.reportHistoryTitle &&
          <Title>{reportsview.reportHistoryTitle}</Title>
        }
        {reportsview.reportHistoryDescription &&
          <p>{reportsview.reportHistoryDescription}</p>
        }
        {routes.reportsView && routes.reportsView.url &&
          <Link className={styles.link} to={routes.reportsView.url}>{t("REPORTS.BACK")}</Link>
        }
      </div>

      {reportsview.reportHistorySubTitle &&
        <h2>{reportsview.reportHistorySubTitle}</h2>
      }

      {reportHistoryListData.state === "LOADED" &&
      reportHistoryListData.data.length > 0 &&
        <SearchableDropdown
          className={styles.districtSelector}
          name="productName"
          variant="primary"
          placeholder={t("REPORTS.HISTORY.SEARCH")}
          value={currentDistrict !== "" ? currentDistrict : reportHistoryListData.data[0].id.toString()}
          onChange={value => {
            const val = value !== null ? value.toString() : value;
            setCurrentDistrict(val);
          }}
        >
          {reportHistoryListData.data.map(item => (
            <DropdownItem
              key={`from_${item.id}`}
              className={styles.inputWrapper}
              value={`${item.id}`}
            >
              {`${item.name}`}
            </DropdownItem>
          ))}
        </SearchableDropdown>
      }

      {reportHistory.state === "LOADED" &&
      reportHistory.data.header &&
      reportHistory.data.table ?
        <HistoryTableLarge
          headers={reportHistory.data.header}
          table={reportHistory.data.table}
        /> :
        <HistoryTableLargeDummy />
      }
      {reportHistory.state === "LOADED" &&
      reportHistory.data.header &&
      reportHistory.data.table ?
        <HistoryTableSmall
          headers={reportHistory.data.header}
          table={reportHistory.data.table}
          current={current}
          onClick={x => setCurrent(current !== x ? x : null)}
        /> :
        <HistoryTableSmallDummy />
      }
      {reportHistory.state === "LOADED" &&
        reportHistory.data.size !== null &&
        reportHistory.data.size > PAGE_SIZE &&
        <div className={styles.paginationWrapper}>
          <Pagination
            className={styles.pagination}
            currentPage={currentPage}
            pageCount={totalPages}
            ButtonComponent={PaginationButton}
            maxButtonCount={isDesktop ? 10 : 5}
          />
        </div>
      }
    </Wrapper>
  );
};

export default ReportsHistoryView;
