/* @flow */

import type { Offer } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";

const OfferListItem = ({ className, count, item: { title, content, image, link, linkText,
} }: { className?: string, item: Offer, count: number }): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const gaRegister = () => {
    gaContext.registerContentClick(`Offer: ${title ?? ""}`, count.toString());
  };

  return (
    <div className={cn(styles.block, styles[`count__${count}`], className)}>
      {image && <img src={image} alt={title || ""} />}
      <div className={styles.wrapper}>
        {
          title &&
          <h2 className={styles.title}>{title}</h2>
        }
        {/* eslint-disable react/no-danger */}
        {
          content &&
          <div dangerouslySetInnerHTML={{ __html: content }} className={styles.text} />
        }
        {/* eslint-enable react/no-danger */}

        {link && <a href={link} onClick={gaRegister}>{linkText || t("OFFERS.LINK")}</a>}
      </div>
    </div>
  );
};

export const DummyOfferListItem = (): React$Node => (
  <div className={styles.dummy_block}>
    <div className={styles.dummy_title} />
    <div className={styles.dummy_text} />
  </div>
);

export default OfferListItem;
