/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";

import Calculator from "./Calculator";

import styles from "./styles.scss";

const CalculatorView = (): React$Node => {
  const {
    routes: { calculatorView: calculatorViewRoute },
  } = useContext(StoreInfoContext);

  return (
    <div className={styles.block}>
      <Helmet
        title={calculatorViewRoute?.title}
      />
      <Calculator />
    </div>
  );
};

export default CalculatorView;
