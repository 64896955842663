/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { TransactionsData } from "data";
import Points from "components/AccountView/TransactionsView/Points";
import { timestampToDateString } from "helpers/locale-utils";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const TransactionTable = (): React$Node => {
  const { content: { transactionsview }, info: { code } } = useContext(StoreInfoContext);
  const t = useTranslate();

  const transaction = useData(TransactionsData);

  if (transaction.state !== "LOADED") {
    return null;
  }

  return (
    <div>
      <h3>{transactionsview.tableHeader}</h3>
      <table className={styles.transactionsTable}>
        <thead>
          <tr>
            {/* Show Date instead of ID for Release Points */}
            {code === "releasefinans_sek" ?
              <th>{transactionsview.tableDate}</th> :
              <th>{transactionsview.tableId}</th>
            }
            <th>{transactionsview.tableTransaction}</th>
            {Boolean(!transactionsview.hidePoints) && <th>{transactionsview.tablePoints}</th>}
            {Boolean(transactionsview.showRankpts) && <th>{transactionsview.tableRankpts}</th>}
          </tr>
        </thead>
        <tbody>
          {transaction.data.slice(0, 3).map(x => (
            <tr key={x.id}>
              {/* Show Date instead of ID for Release Points */}
              {code === "releasefinans_sek" ?
                <td>{timestampToDateString(x.createdAt)}</td> :
                <td>{x.id}</td>
              }
              <td>{x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description}</td>
              {Boolean(!transactionsview.hidePoints) &&
                <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                  <Points points={x.points} />
                </td>
              }
              {Boolean(transactionsview.showRankpts) &&
                <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                  <Points points={x.rank} />
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
