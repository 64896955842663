/* @flow */

import React from "react";
import cn from "classnames";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type Props = {
  className?: string,
  acceptanceText: string,
  accepted: boolean,
  callback: (value: boolean) => void,
};

const Notice = ({
  className,
  acceptanceText,
  callback,
  accepted,
}: Props): React$Node => {
  const t = useTranslate();

  return (
    <div
      className={cn(
        styles.block,
        { [styles.visible]: accepted },
        className
      )}
    >
      {!accepted ? (
        <aside className={cn(styles.notice, className, "awardit-Notice")}>
          <div className={styles.content}>
            {/* eslint-disable react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: acceptanceText }} className={styles.text} />
            {/* eslint-enable react/no-danger */}
            <div className={styles.buttons}>
              <Button
                className={cn(styles.button, styles.button__all)}
                onClick={() => callback(true)}
              >
                <p>{t("COOKIE_BANNER.ALL")}</p>
              </Button>
              <Button
                className={cn(styles.button, styles.button__necessary)}
                onClick={() => callback(true)}
              >
                <p>{t("COOKIE_BANNER.NECESSARY")}</p>
              </Button>
            </div>
          </div>
        </aside>
      ) : null}
    </div>
  );
};

export default Notice;
